import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import findImage from '../../utils/findImage'

import Layout from '../../components/Layout'
import FullPage from '../../components/FullPage'
import WorkTitleBlock from '../../components/WorkTitleBlock'

export const frontmatter = {
  title: 'RFS Logo and Identity',
  type: 'Identity',
  client: 'RFS',
  path: '/works/identity/rfs-style/',
  date: '2009-08-28',
  image: 'rfs-style-demo',
  favorite: true,
}

export default ({ location }) => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/rfs/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />
      <FullPage>
        <figure className="text-center">
          <Img
            fluid={findImage(images, 'rfs-logo-big')}
            alt="RFS Logo"
            style={{ maxWidth: '540px', margin: 'auto' }}
          />
        </figure>
      </FullPage>

      <WorkTitleBlock {...frontmatter} />
    </Layout>
  )
}
